.footer_content {
    min-height: 96px;
    text-align: center;
    background-color: #61185a;
}

.footer_text {
    padding-top: 2em;
    margin: auto;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}