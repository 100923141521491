@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@400;500;600;700;800&display=swap);

* {
    margin: unset;
    font-family: 'Baloo Bhaina 2', cursive;
}

button {
    border: none;
    border-radius: 4px;
    background-color: #61185a;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

button:hover {
    background-color: #8a247d;
}

.btn_inverse {
    background-color: #9e44ae;
    border-radius: 4px;
    border: solid 3px #ffffff;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}

.btn_inverse:hover {
    background-color: #8a247d;
}

/* width */
::-webkit-scrollbar {
    width: .5em;
    height: .5em;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(210, 210, 210);
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #61185a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #61185a;
}
.header_container {
    height: 236px;
    display: flex;
    flex-direction: column;
    background-image: url(/static/media/header_banner_icon.121e35bd.svg);
}

.header_logo {
    width: auto;
    height: 50px;
    margin-top: 2em;
}

.header_line {
    height: 1px;
    width: 23em;
    margin: 2em auto;
    background-color: white;
}

.header_title {
    width: auto;
    color: #ffffff;
    font-size: 35px;
    text-align: center;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1;
}

@media only screen and (max-width: 980px) {
    .header_container {
        background-position-x: -68em;
        background-size: cover;
    }
    .header_title {
        width: auto;
        font-size: 22px;
    }
}
.formalize_content {
    width: auto;
    height: auto;
    padding-bottom: 40px;
    background-color: #ebebeb;
    text-align: -webkit-center;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url(/static/media/formalize_banner.41ff96df.svg);
}

.formalize_title {
    height: auto;
    width: 662px;
    padding-top: 40px;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: normal;
    color: #051a31;
    text-align: left;
}

.formalize_description {
    width: 662px;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #051a31;
    margin-bottom: 2em;
}

.formalize_button {
    padding: .5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 80px;
    margin: auto 2em auto auto;
}

.formalize_alt_button {
    margin: auto;
    padding: .5em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 80px;
    background-color: #3a2059;
}

.formalize_button_sizer {
    display: flex;
    flex-direction: row;
    width: 662px;
    text-align: start;
}

@media only screen and (max-width: 980px) {
    .formalize_content {
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 40px;
    }
    .formalize_title {
        width: auto;
        font-size: 22px;
        text-align: center;
    }
    .formalize_description {
        width: auto;
        height: auto;
        line-height: 1.25;
        text-align: center;
        margin-bottom: 1em;
    }
    .formalize_button_sizer {
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
        text-align: center;
    }
    .formalize_button {
        margin: auto auto 2em;
        font-size: 20px;
    }
}
.services_content {
    height: 470px;
    text-align: center;
    background-color: #9e44ae;
}

.services_title {
    width: auto;
    height: auto;
    padding-top: 80px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.service_description {
    width: auto;
    height: auto;
    margin-bottom: 40px;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.services_card {
    width: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    place-content: center;
}

.service_card {
    display: flex;
    flex-direction: column;
    width: 316px;
    height: 162px;
    margin-right: 7px;
    margin-left: 7px;
    margin-bottom: 50px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.service_card_icon {
    width: 4.5em;
    height: 5em;
    margin: auto 1em;
}

.service_card_title {
    max-width: 200px;
    width: auto;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: left;
    color: #002042;
}

.service_card_description {
    width: auto;
    max-width: 200px;
    min-height: 10px;
    max-height: 40px;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    margin-bottom: 16px;
}

.service_card_text_container {
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-top: .5em;
}

.service_card_button_container {
    height: 20px;
    padding: .5em;
    background-color: #61185a;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    float: left;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    cursor: pointer;
    margin: auto auto 1em;
}

.service_card_button_container:hover {
    background-color: #8a247d;
}

.service_card_button {

}

.services_button {
    margin: auto;
    width: 340px;
    height: 65px;
}

.modal_close_icon {
    right: 20px;
    cursor: pointer;
    position: absolute;
}

.modal_title {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #61185a;
    margin-bottom: 25px;
}

.modal_description {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #0f1b45;
    margin-bottom: 20px;
}

.modal_video {
    z-index: 2;
    height: 20em;
    margin: auto;
}

.modal_button {
    padding: 1em;
    height: 50px;
    font-size: 16px;
    margin: 1.5em auto auto;
}

.videos_carousel_container {
    display: flex;
    overflow: auto;
    padding-bottom: 1em;
}

.videos_carousel {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex-direction: row;
}

.videos_carousel .modal_video {
    width: 20em;
    margin-left: .5em;
    margin-right: .5em;
}

@media only screen and (max-width: 980px) {
    .width_mobile {
        margin-left: 41.5em;
    }
    .services_content {
        padding-bottom: 45px;
    }
    .services_card {
        overflow-x: scroll;
        width: auto;
        margin-right: 1em;
    }
    .service_card {
        width: 400px;
    }
    .service_card_icon {
        width: auto;
        height: auto;
        margin: auto auto auto 20px;
    }
    .service_card_title {
        width: 200px;
        margin-left: 15px;
    }
    .service_card_description {
        width: 300px;
        max-height: 200px;
        margin-left: 15px;
        margin-right: 30px;
    }
    .services_button {
        margin-bottom: 45px;
    }
    .modal_video {
        height: 12em !important;
        width: 18em !important;
    }
    .ReactModal__Content {
        width: 20em !important;
    }
}
.benefits_content {
    display: flex;
    margin-bottom: 70px;
    flex-direction: column;
    padding-left: 80px;
    padding-right: 80px;
}

.benefits_header_content {
    display: flex;
    padding-top: 56px;
    align-self: center;
    flex-direction: row;
}

.benefits_header_title {
    font-size: 26px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: normal;
    text-align: center;
    color: #051a31;
    margin-bottom: 30px;
}

.benefits_header_description {
    height: 38px;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #051a31;
}

.benefits_header_button {
    width: 340px;
    height: 65px;
}

.benefits_partners_list_line {
    height: 1px;
    width: 80em;
    margin: 20px auto;
    background-color: #bfbfbf;
}

.benefits_partners_list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style-type: none;
    justify-content: center;
}

.benefits_partner {
    max-width: 180px;
    margin: auto 30px;
}

.partner_soon {
    opacity: 0.8;
    background-color: #e4e4e4;
}

.benefits_soon_partner {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
}

.benefits_partner_image {
    height: auto;
    max-width: 140px;
}

.benefits_partner_name {
    margin: auto;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #051a31;
}

.benefits_mobile_only {
    display: none;
}

.benefits_computer_only {
    display: unset;
}

@media only screen and (max-width: 1300px) {
    .benefits_partners_list {
        justify-content: center;
    }
}

@media only screen and (max-width: 980px) {
    .benefits_partners_list_line {
        display: none;
    }

    .benefits_content {
        display: block;
        padding: unset;
        margin-bottom: 40px;
    }

    .benefits_header_content {
        display: flex;
        flex-direction: column;
    }

    .benefits_header_texts {
        text-align: center;
        margin-bottom: 40px;
    }
    .benefits_header_title {
        width: auto;
        margin-right: auto;
        text-align: center;
    }
    .benefits_header_description {
        width: auto;
        text-align: center;
    }
    .benefits_partners_list {
        display: flex;
        flex-direction: row;
    }
    .benefits_partner {
        width: auto;
        margin: auto auto;
    }
    .benefits_header_button {
        width: auto;
        font-size: 18px;
        margin: auto;
    }
}
.partners_content {
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-color: #e4e4e4;
    background-repeat: no-repeat;
    background-image: url("/assets/dicasmei_vector.svg");
}

.partners_title {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.77;
    letter-spacing: normal;
    text-align: center;
    color: #051a31;
}

.partners_description {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #051a31;
    margin-bottom: 50px;
}

.partners_list {
    display: flex;
    flex-direction: row;
    margin: auto auto 50px;
}

.partner {
    margin: auto 40px;
}

.partner_image {
    max-height: 80px;
    max-width: 180px;
}

@media only screen and (max-width: 980px) {
    .partners_list {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        width: 24em;
    }
}
.footer_content {
    min-height: 96px;
    text-align: center;
    background-color: #61185a;
}

.footer_text {
    padding-top: 2em;
    margin: auto;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
