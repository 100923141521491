@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2:wght@400;500;600;700;800&display=swap');

* {
    margin: unset;
    font-family: 'Baloo Bhaina 2', cursive;
}

button {
    border: none;
    border-radius: 4px;
    background-color: #61185a;
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

button:hover {
    background-color: #8a247d;
}

.btn_inverse {
    background-color: #9e44ae;
    border-radius: 4px;
    border: solid 3px #ffffff;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}

.btn_inverse:hover {
    background-color: #8a247d;
}

/* width */
::-webkit-scrollbar {
    width: .5em;
    height: .5em;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(210, 210, 210);
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #61185a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #61185a;
}